<script>
/* global locale */
import ScrollClass from '../../shared/scrolling_class'
import NhlInfoFormCheckboxes from '../../nhl/nhl_info_request_form_checkboxes.vue'
import { trackPageView as gtmTrackPageView } from '../google_tag_manager.js'
import { Field, Form as VForm, ErrorMessage, defineRule } from 'vee-validate'
import PrivacyCheckbox from '../privacy_checkbox.vue'

defineRule('required', value => {
  if (!value || !value.length) {
    return 'This field is required'
  }
  return true
})

let sanitizeHtml = require('sanitize-html')
const sidebar_info = sanitizeHtml(window.sidebar_info, {
  allowedTags: ['span', 'a'],
  allowedAttributes: {
    'a': ['href', 'class', 'id']
  },
  allowedSchemesAppliedToAttributes: ['tel']
})

export default {
  name: 'InfoRequestForm',
  components: {
    NhlInfoFormCheckboxes,
    PrivacyCheckbox,
    Field,
    VForm,
    ErrorMessage
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['instructions'],
  data() {
    return {
      stuff: {
        type: Array
      },
      show: true,
      comments: '',
      company_name: '',
      reseller_checkbox: false,
      corporate_request: false,
      disabled: true,
      email: '',
      event_id: '',
      event_or_company_name: '',
      first_name: '',
      ga_id: {
        default: window.googleAnalyticsId,
        require: false,
        type: String
      },
      gcl_id: '',
      how_many: '',
      in_eu: false,
      in_us: false,
      nba_info_request: {
        isNbaForm: false,
        isInsideUS: false,
        showNbaInfo: false,
        partner_products_services_consent: false,
        partner_privacy_policy_consent: false
      },
      wnba_info_request: {
        isWnbaForm: false,
        isInsideUS: false,
        showWnbaInfo: false,
        partner_products_services_consent: false,
        partner_privacy_policy_consent: false
      },
      xgames_info_request: {
        isXgamesForm: false,
        partner_products_services_consent: false,
        partner_privacy_policy_consent: false
      },
      nhl_info_request: {
        isNhlForm: false,
        isInCanada: false,
        isInsideUS: false,
        showNhlInfo: false,
        nhl_member_club_opt_in: false,
        nhl_business_partner_opt_in: false,
        partner_marketing_consent: false
      },
      last_name: '',
      phone: '',
      processingError: false,
      eventOptions: [{
        id: '',
        display_name: this.$t('form.event')
      }],
      categoryOptions: [],
      sidebar: true,
      brand_name: window.brand_name,
      info: sidebar_info,
      info_title: this.$t('request_packages'),
      confirmed: false,
      default_height: '',
      nba_favorite_teams: [
        'Atlanta Hawks',
        'Boston Celtics',
        'Brooklyn Nets',
        'Charlotte Hornets',
        'Chicago Bulls',
        'Cleveland Cavaliers',
        'Dallas Mavericks',
        'Denver Nuggets',
        'Detroit Pistons',
        'Golden State Warriors',
        'Houston Rockets',
        'Indiana Pacers',
        'LA Clippers',
        'Los Angeles Lakers',
        'Memphis Grizzlies',
        'Miami Heat',
        'Milwaukee Bucks',
        'Minnesota Timberwolves',
        'New Orleans Pelicans',
        'New York Knicks',
        'Oklahoma City Thunder',
        'Orlando Magic',
        'Philadelphia 76ers',
        'Phoenix Suns',
        'Portland Trail Blazers',
        'Sacramento Kings',
        'San Antonio Spurs',
        'Toronto Raptors',
        'Utah Jazz',
        'Washington Wizards'
      ],
      wnba_favorite_teams: [
        'Atlanta Dream',
        'Chicago Sky',
        'Connecticut Sun',
        'Dallas Wings',
        'Indiana Fever',
        'Las Vegas Aces',
        'Los Angeles Sparks',
        'Minnesota Lynx',
        'New York Liberty',
        'Phoenix Mercury',
        'Seattle Storm',
        'Washington Mystics'
      ],
      nhl_favorite_teams: [
        'Anaheim Ducks',
        'Boston Bruins',
        'Buffalo Sabres',
        'Calgary Flames',
        'Carolina Hurricanes',
        'Chicago Blackhawks',
        'Colorado Avalanche',
        'Columbus Blue Jackets',
        'Dallas Stars',
        'Detroit Red Wings',
        'Edmonton Oilers',
        'Florida Panthers',
        'Los Angeles Kings',
        'Minnesota Wild',
        'Montréal Canadiens',
        'Nashville Predators',
        'New Jersey Devils',
        'New York Islanders',
        'New York Rangers',
        'Ottawa Senators',
        'Philadelphia Flyers',
        'Pittsburgh Penguins',
        'San Jose Sharks',
        'Seattle Kraken',
        'St. Louis Blues',
        'Tampa Bay Lightning',
        'Toronto Maple Leafs',
        'Utah Hockey Club',
        'Vancouver Canucks',
        'Vegas Golden Knights',
        'Washington Capitals',
        'Winnipeg Jets'
      ],
      favorite_team: ''
    }
  },
  computed: {
    howManyOptions() {
      let options = [{ text: this.$t('form.how_many'), value: '' }]
      let i = 1
      while (i < 10) {
        options.push({ text: i, value: i })
        i++
      }
      options.push({ text: '10+', value: 10 })
      return options
    },

    resbeatWindow() {
      return (window.location.hostname.includes('resbeat'))
    },
    quintroomsWindow() {
      return (window.brand_name === 'QuintRooms')
    },
    roomcashWindow() {
      return (window.brand_name == 'RoomCash')
    },
    sportsnetWindow() {
      return (window.brand_name == 'Sportsnet')
    },
  },

  mounted() {
    this.fetchEvents()
    this.phoneLink()
    this.isSidebar()
    this.setEventOptions()
    this.setClickId()
  },

  updated() {
    this.setCorporateRequest()
  },

  methods: {
    validateEmail(emailValue){
      if (!emailValue) {
        return 'This field is required'
      }
      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i

      if (!regex.test(emailValue)) {
        return 'Please enter a valid email'
      }
      // All is good
      return true
    },

    noCreditCard(comments) {
      if (!comments) return true
      var comment = comments.replace(/-|\s/g, '')
      var strippedComment = comment.replace(/[^0-9]+/, '')
      const visa = /^4[0-9]{12}(?:[0-9]{3})?$/i
      const mastercard = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/i
      const americanExpress = /^3[47][0-9]{13}$/i
      const dinersClub = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/i
      const discover = /^6(?:011|5[0-9]{2})[0-9]{12}$/i
      const jcb = /^(?:2131|1800|35\d{3})\d{11}$/i
      if(visa.test(strippedComment) || mastercard.test(strippedComment) || americanExpress.test(strippedComment) || dinersClub.test(strippedComment) || discover.test(strippedComment) || jcb.test(strippedComment)) {

        return false
      }
      return true
    },

    validatePhone(phoneValue){
      if(!phoneValue) {
        return 'This field is required'
      }

      if(phoneValue.length < 8){
        return 'Please enter a valid phone number'
      }
      return true
    },

    validateEvent(event) {
      if(!event) {
        return 'Please select an event'
      }
      return true
    },
    marketingConsent: (value) => {
      if (value && value.length) {
        return true
      }

      return 'Please consent to the privacy policy'
    },

    validateCompanyName(name) {
      if(this.corporate_request && !name) {
        return false
      }
      return true
    },
    // boxChecked(boolean){
    //   if(boolean){
    //     return 'Please consent to the privacy policy'
    //   }
    //   return true
    // },

    initEvents(body) {
      if (body.categories) {
        this.categoryOptions = body.categories
        for (let category of body.categories) {
          this.selectEvent(category.events)
        }
      } else if (body.events.length > 1) {
        this.eventOptions = this.eventOptions.concat(body.events)
        this.selectEvent(body.events)
      } else {
        this.event_id = body.events[0].id
      }
      this.disabled = false
    },
    fetchEvents: async function() {
      try {
        var eventTypeDiv = document.getElementById('eventTypeDiv')
        var eventParam = ''
        if (eventTypeDiv && eventTypeDiv.dataset.eventType) eventParam = `&event_type=${eventTypeDiv.dataset.eventType}`
        const response = await fetch(`/api/info_requests/dropdown.json?locale=${locale}${eventParam}`)
        const json = await response.json()
        this.in_eu = json.in_eu
        this.setLocationValues(json)
        this.initEvents(json)
        this.setNbaForm()
        this.setWnbaForm()
        this.setNhlForm()
        this.setXgamesForm()
        this.setCorporateRequest()
        this.in_us = json.in_us
      }
      catch {
        this.processingError = true
      }
    },
    setLocationValues(json){
      this.nba_info_request.isInsideUS = json.in_us
      this.wnba_info_request.isInsideUS = json.in_us
      this.nhl_info_request.isInCanada = json.in_canada
      this.nhl_info_request.isInsideUS = json.in_us
    },
    pathMatch(path) {
      return path.match(/^\/([a-z]{2}(-[A-Z]{2})?)(\/.*|$)/)
    },
    pathLang(path) {
      if (path == null) { path = window.location.pathname }
      const matches = this.pathMatch(path)
      return matches != null ? matches[1] : ''
    },
    getPathNameWithoutLocale() {
      const loc = window.location
      const currentLang = this.pathLang(loc.pathname)
      const langRegex = new RegExp(`^/${currentLang}`)
      const pathname =  currentLang != '' ? loc.pathname.split(langRegex)[1] : loc.pathname
      return pathname
    },
    formData() {
      let info_request_data = {
        event_id: this.event_id,
        comments: this.comments,
        reseller_checkbox: this.reseller_checkbox,
        corporate_request: this.corporate_request,
        company_name: this.corporate_request ? this.company_name : '',
        email: this.email,
        first_name: this.first_name,
        how_many: this.how_many,
        last_name: this.last_name,
        phone: this.phone,
        ga_id: this.ga_id,
        gcl_id: this.gcl_id,
        pathname: this.getPathNameWithoutLocale(),
        referring_page: `${window.location.pathname}${window.location.search}`
      }

      if (this.nba_info_request.isNbaForm) {
        return {
          locale: locale,
          info_request: {
            ...info_request_data,
            favorite_team: this.favorite_team,
            comments: this.comments,
            reseller_checkbox: this.reseller_checkbox,
            corporate_request: this.corporate_request,
            company_name: this.corporate_request ? this.company_name : '',
            email: this.email,
            first_name: this.first_name,
            how_many: this.how_many,
            last_name: this.last_name,
            partner_products_services_consent: this.nba_info_request.partner_products_services_consent,
            partner_privacy_policy_consent: this.nba_info_request.partner_privacy_policy_consent,
            phone: this.phone,
            ga_id: this.ga_id,
            gcl_id: this.gcl_id,
            pathname: this.getPathNameWithoutLocale(),
            referring_page: `${window.location.pathname}${window.location.search}`
          }
        }
      } else if(this.wnba_info_request.isWnbaForm) {
        return {
          locale: locale,
          info_request: {
            ...info_request_data,
            favorite_team: this.favorite_team,
            comments: this.comments,
            reseller_checkbox: this.reseller_checkbox,
            corporate_request: this.corporate_request,
            company_name: this.corporate_request ? this.company_name : '',
            email: this.email,
            first_name: this.first_name,
            how_many: this.how_many,
            last_name: this.last_name,
            partner_products_services_consent: this.wnba_info_request.partner_products_services_consent,
            partner_privacy_policy_consent: this.wnba_info_request.partner_privacy_policy_consent,
            phone: this.phone,
            ga_id: this.ga_id,
            gcl_id: this.gcl_id,
            pathname: this.getPathNameWithoutLocale(),
            referring_page: `${window.location.pathname}${window.location.search}`
          }
        }
      } else if(this.nhl_info_request.isNhlForm) {
        return {
          locale: locale,
          info_request: {
            ...info_request_data,
            favorite_team: this.favorite_team,
            nhl_member_club_opt_in: this.nhl_info_request.nhl_member_club_opt_in,
            nhl_business_partner_opt_in: this.nhl_info_request.nhl_business_partner_opt_in,
            partner_products_services_consent: this.nhl_info_request.partner_marketing_consent,
          }
        }
      } else if (this.xgames_info_request.isXgamesForm) {
        return {
          locale: locale,
          info_request: {
            ...info_request_data,
            partner_products_services_consent: this.xgames_info_request.partner_privacy_policy_consent,
            partner_privacy_policy_consent: this.xgames_info_request.partner_privacy_policy_consent
          }
        }
      } else {
        return {
          locale: locale,
          info_request: {
            ...info_request_data,
            event_or_company_name: this.event_or_company_name
          }
        }
      }
    },
    postFormData: async function () {

      try {
        const response = await fetch('/info_requests', {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(this.formData())
        })
        if(!response.ok) { throw 'Bad response' }
        this.disabled = false
        this.confirmed = true
        this.info_title = this.$t('messages.thank_you')
        gtmTrackPageView(`https://${window.location.host}/info-request-thank-you`, 'Info Request Thank You', '/info-request-thank-you')
      } catch {
        this.processingError = true
        this.disabled = false
      }
    },
    setNbaForm() {
      if (window.brand_name === 'NBA Premium' || window.brand_name === 'NBA Experiences') {
        this.nba_info_request.isNbaForm = true
        this.nba_info_request.partner_privacy_policy_consent = false
      }
    },
    setWnbaForm() {
      if (window.name == 'wnba') {
        this.wnba_info_request.isWnbaForm = true
        this.wnba_info_request.partner_privacy_policy_consent = false
        this.wnba_info_request.partner_products_services_consent =  false
        if(this.wnba_info_request.isInsideUS) {
          this.wnba_info_request.partner_products_services_consent = true
        }
      }
    },
    setXgamesForm() {
      if(window.name == 'x-games') {
        this.xgames_info_request.isXgamesForm =  true
        this.xgames_info_request.partner_privacy_policy_consent = false
        this.xgames_info_request.partner_products_services_consent =  false
      }
    },
    setCorporateRequest() {
      if (window.brand_name === 'QuintRooms') {
        this.corporate_request = true
      }
    },
    setNhlForm() {
      if (window.brand_name === 'NHL Experiences') {
        this.nhl_info_request.isNhlForm = true
      }
    },
    setClickId() {
      if(window.localStorage.gclid != undefined) {
        this.gcl_id = window.localStorage.gclid
      }
    },
    selectEvent(events) {
      for (let event of events) {
        if (event.path && window.location.pathname.includes(event.path)) {
          this.event_id = event.id
        }
      }
    },
    setDefaultHeight() {
      if (document.getElementById('sidebar') == null) {
        this.default_height = window.innerHeight + 1
      } else {
        this.default_height = document.getElementById('sidebar').clientHeight
      }
    },
    getMainHeight() {
      if (screen.width >= 1200) {
        this.setDefaultHeight()
        document.getElementsByTagName('main')[0].style.minHeight = this.default_height + 'px'
        ScrollClass.scrollToClass('request-confirmation')
      } else {
        ScrollClass.scrollToClass('form-title')
      }
    },
    submitForm() {
      // anti-spam logic
      let honeypot = document.getElementById('honeypot')
      if(honeypot.value === '') {
        // e.preventDefault()
        this.disabled = true
        this.getMainHeight()
        this.postFormData()
      }},

    wipeData() {
      this.comments = ''
      this.corporate_request = false
      this.reseller_checkbox = false
      this.company_name = ''
      this.email = ''
      this.first_name = ''
      this.how_many = ''
      this.last_name = ''
      this.phone = ''
    },
    closeConfirmation() {
      this.confirmed = false
      this.info_title = this.$t('request_packages')
      // this.wipeData()
      this.$refs.form.resetForm()
      this.setWnbaForm()
      this.setXgamesForm()
    },
    phoneLink() {
      var location = document.getElementById('country-code').dataset.code
      var phoneNumber = window.phone_numbers[location] || window.phone_numbers['default']
      return phoneNumber
    },
    isSidebar() {
      if($('.sidebar').length == 0 ) {
        this.sidebar = false
      }
    },
    setEventOptions() {
      if ( this.resbeatWindow){
        this.eventOptions[0].display_name = 'Product Interest'
      }
    },
    updateFontColor() {
      if(window.brand_name == 'NBA Experiences' || window.brand_name == 'NBA Premium') {
        let select = document.getElementById('info_request_how_many')
        if(select && select.value > 0){
          select.style.color = '#000'
        } else {
          select.style.color = '#8e8e8e'
        }
      }
    }
  }
}
</script>
